<!-- home -->
<template>
  <div class="home">
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchData"
      @infoWindowClose="infoWindowClose"
      ref="valueLabel"
    ></s-search>

    <div class="explain_map_flex">
      <div class="explain_map">
        <p>只展示绑定过摄像头的工地，以设备最后上传的经纬度为准</p>
      </div>
      <div class="explain_mapBtn">
        <a-tooltip>
          <template slot="title">全屏显示</template>
          <a-button type="primary" @click="MapZoom()" class="map_quanpingBTN">
            <!-- <a-icon type="fullscreen"/> -->
            <img
              src="../../assets/images/site_img/fullscreen_img.png"
              alt=""
              class="fullscreen_img"
            />
          </a-button>
        </a-tooltip>
      </div>
    </div>
    <div class="map_status">
      <div class="status-con" id="status-con">
        <!-- 只展示绑定过摄像头的工地 -->
        <div class="status-item" :class="{ status1: device_status === '1' }">
          <img src="../../assets/images/map_success.png" alt="" />
          <span>在线</span>
        </div>
        <div class="status-item" :class="{ status3: device_status === '2' }">
          <img src="../../assets/images/map_error.png" alt="" />
          <span>离线</span>
        </div>
        <div class="status-item">
          <img src="../../assets/images/map_warn.png" alt="" />
          <span>无摄像头</span>
        </div>
      </div>

      <div id="screen">
        <div id="container" style="width: 100%; height: 100%"></div>
        <canvas id="canvas"></canvas>

        <div class="exit_control">
          <div id="exit_icon" class="exit_icon" ref="exit_icon">
            <a-tooltip
              placement="bottom"
              :getPopupContainer="getPopupContainer"
            >
              <template slot="title">退出全屏</template>
              <a-button type="primary" @click="MapZoom_Exit()">
                <img
                  src="../../assets/images/site_img/fullscreen_exit_img.png"
                  alt=""
                  class="fullscreen_exit_img"
                />
              </a-button>
            </a-tooltip>
          </div>
        </div>
      </div>
    </div>

    <!-- 展开弹出框 -->
    <a-drawer
      :title="title"
      :width="800"
      placement="right"
      :visible="visible"
      :destroyOnClose="true"
      @close="onClose"
      class="site-modal"
    >
      <SitePreview
        :record="record"
        :SiteList_previer_true="SiteList_previer_true"
        @JumpSubpage="JumpSubpage"
        @onClose="onClosePreview"
        v-if="SitePreview"
        ref="SitePreview"
      ></SitePreview>
      <SiteSnapshot :record="record" v-if="SiteSnapshot"></SiteSnapshot>
      <SitePlayback
        :record="record"
        v-if="SitePlayback"
        ref="SitePlayback"
      ></SitePlayback>
      <SiteAttend :record="record" v-if="SiteAttend"></SiteAttend>
      <SiteParticulars
        :record="record"
        v-if="SiteParticulars"
      ></SiteParticulars>
    </a-drawer>
  </div>
</template>

<script>
import { SSearch } from "@/components";
import { GetSiteMap, Getmapsitedetail } from "@/api/site";
import SitePreview from "@/views/site/SitePreview.vue"; //直播
import SiteSnapshot from "@/views/site/SiteSnapshot.vue"; //快照
import SitePlayback from "@/views/site/SitePlayback.vue"; //回放
import SiteAttend from "@/views/site/SiteAttend.vue"; //考勤
import SiteParticulars from "@/views/site/SiteParticulars.vue"; //详情
import screenfull from "screenfull";

// 搜索条件
const queryItems = [
  { label: "项目经理", value: "w_name_jl", type: "input" },
  {
    label: "工地所属分公司",
    value: "bc_name",
    type: "select",
    selectOption: "CompanyList",
  },
  {
    label: "设备所属分公司",
    value: "BcCs_id",
    type: "TreeSelect",
    range: ["bc_id", "cs_id"],
    selectOption: "CompanystoreList",
    defaultValue: ["", ""],
  },
  {
    label: "工地名称",
    value: "ws_name",
    type: "input",
    scopedSlots: { customRender: "ws_name" },
  },

  { label: "工程管家", value: "w_name_gj", type: "input" },
  {
    label: "是否有摄像头",
    value: "camera",
    type: "select",
    selectOption: "Camera",
    defaultValue: "1",
  },
  {
    label: "设备状态",
    value: "device_status",
    type: "select",
    selectOption: "SiteDeviceStatus",
    defaultValue: "0",
  },

  {
    label: "无人施工天数",
    value: "no_work",
    type: "select",
    selectOption: "NoWorksSiteList",
  },
  // { label: "地区", value: "ws_area", type: "area" },
];
const centerList = {
  "": "北京市",
  0: "北京市",
  5: "天津市",
  8: "天津市",
  20: "天津市",
  3: "北京市",
  4: "北京市",
  6: "北京市",
  23: "北京市",
  24: "北京市",
  25: "北京市",
  9: "成都市",
  17: "大连市",
  15: "哈尔滨市",
  14: "济南市",
  13: "南京市",
  22: "青岛市",
  10: "青岛市",
  7: "石家庄市",
  12: "武汉市",
  16: "西安市",
  19: "西安市",
  21: "西安市",
  18: "郑州市",
  11: "重庆市",
  26: "北京市",
};
export default {
  name: "SiteMap",
  components: {
    SSearch,
    GetSiteMap,
    SitePreview,
    SiteSnapshot,
    SitePlayback,
    SiteAttend,
    SiteParticulars,
  },
  data() {
    this.queryItems = queryItems;
    return {
      ws_id: "",
      points1: [],
      queryParam: {
        camera: "1",
      },
      record: {},
      SitePreview: false,
      SiteSnapshot: false,
      SitePlayback: false,
      SiteAttend: false,
      SiteDetail: false,
      SiteParticulars: false,
      visible: false,
      SiteList_previer_true: true,
      title: "",
      isFull: "", //全屏检查
      device_status: "",
      bcName: "",
      center: "",
      // center: "北京市",
      // center: { lng: 119.451423, lat: 39.860703 },
      valueLabel: "",
      map: null,
      cityCenter: "",
      Inputaddress: "",
      isShowBtns: [],
    };
  },

  created() {
    this.ws_id = this.$route.query.ws_id;
    window.JumpSubpage = this.JumpSubpage;
    window.jumpToSiteList = this.jumpToSiteList;
    window.onresize = () => {
      //调用判断全屏的方法，用来监听
      this.checkFull();
    };
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
    this.fetchData();
    var myCity = new BMapGL.LocalCity();
    myCity.get(this.cityResult);
    // this.initMap();
    // console.log(this.$refs.valueLabel.valueLabel);
    // this.map = new BMapGL.Map("container"); // 创建地图实例
    // this.map.centerAndZoom(this.center, 10); // 初始化地图，设置中心点坐标和地图级别
    // this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    // 监听搜索组件 设备状态和是否有摄像头，实现联动
    // this.$watch(() => {
    //   let selectValue = this.$refs.valueLabel.selectValue;
    // });
  },
  watch: {
    isFull(newValue, oldValue) {
      event.keyCode = 0;
      // console.log("监听是否退出全屏");
      if (this.isFull == false) {
        // let status_con = document.getElementById("status-con");
        // status_con.style.display = "block";
        let exit_icon = document.getElementById("exit_icon");
        exit_icon.style.display = "none";
      }
      if (this.isFull == true) {
        let exit_icon = document.getElementById("exit_icon");
        exit_icon.style.display = "block";
        console.log("111");
      }
    },
    // 监听分公司查询条件的变化，改变地图中心点的位置
    bcName(newValue, old) {
      // console.log(newValue);
      // 根据分公司id，设置地图中心点
      function formatData(a) {
        return centerList[a];
      }
      this.center = formatData(newValue);
    },

    valueLabel(newValue) {
      console.log(newValue);
      if (newValue.indexOf("北京") > -1) {
        this.center = "北京市";
      } else if (newValue.indexOf("天津") > -1) {
        this.center = "天津市";
      } else if (newValue.indexOf("成都") > -1) {
        this.center = "成都市";
      } else if (newValue.indexOf("郑州") > -1) {
        this.center = "郑州市";
      } else if (newValue.indexOf("西安") > -1) {
        this.center = "西安市";
      } else if (newValue.indexOf("重庆") > -1) {
        this.center = "重庆市";
      } else if (newValue.indexOf("武汉") > -1) {
        this.center = "武汉市";
      } else if (newValue.indexOf("青岛") > -1) {
        this.center = "青岛市";
      } else if (newValue.indexOf("大连") > -1) {
        this.center = "大连市";
      } else if (newValue.indexOf("哈尔滨") > -1) {
        this.center = "哈尔滨市";
      } else if (newValue.indexOf("济南") > -1) {
        this.center = "济南市";
      } else if (newValue.indexOf("南京") > -1) {
        this.center = "南京市";
      }
    },
  },
  methods: {
    cityResult(result) {
      var cityName = result.name;
      this.center = cityName;
      console.log("mounted_cityResult", cityName, this.cityCenter);
    },
    fetchData(data) {
      this.queryParam = data
        ? data
        : {
            // ws_area: "",
            bc_name: "",
            // w_name: "",
            ws_name: "",
            w_name_gj: "",
            camera: "1", //有无摄像头
            // d_status: "",
            no_work: "",
            device_status: "",
            w_name_jl: "",
            ws_id: "",
            BcCs_id: "",
          };
      this.getMap();
    },
    async getMap() {
      let order = [
        "bc_name",
        "ws_name",
        "w_name_jl",
        "w_name_gj",
        "camera",
        "device_status",
        "no_work",
      ];
      let params = {
        ...this.queryParam,

        ws_id: this.queryParam.ws_id,
        bc_name: this.queryParam.bc_name,
        ws_name: this.queryParam.ws_name,
        w_name_jl: this.queryParam.w_name_jl, //工地项目经理
        w_name_gj: this.queryParam.w_name_gj, //工地管家
        camera: this.queryParam.camera, //有无摄像头
        device_status: this.queryParam.device_status, //摄像头状态
        no_work: this.queryParam.no_work,
        gj_phone: this.queryParam.gj_phone,
        jl_phone: this.queryParam.jl_phone,
        BcCs_id: "",
        verify: this.$verify({ ...this.queryParam }, order),
      };
      this.bcName = params["bc_name"] ? params["bc_name"] : "";
      const points1 = [];
      await GetSiteMap(params)
        .then((res) => {
          if (res && res.data) {
            // console.log(res);
            res.data.forEach((el) => {
              points1.push({
                lng: el.ws_long,
                ws_id: el.ws_id,
                lat: el.ws_lat,
                // ws_name: el.ws_name
                //   ? el.ws_name.replace(/直辖市|-北京|-天津/g, "")
                //   : el.ws_name, //工地名称
                // w_name_gj: el.gj_name, //工地管家
                // w_name_jl: el.jl_name, //工地项目经理
                bc_name: el.bc_name, //分公司id
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                no_work: el.no_work, //无人施工
                ws_status: el.ws_status, //工地状态
                // gj_phone: el.gj_phone,
                // jl_phone: el.jl_phone,
                show: false,
                // icon: icon,
              });
            });
            this.points1 = points1;
          }
        })
        .then(() => {
          this.initMap();
        });
    },
    initMap() {
      let that = this;
      let map = null;

      function baiduMapInit() {
        map = new BMapGL.Map("container"); // 创建地图实例
        // var point = new BMapGL.Point(116.404, 39.915); // 创建点坐标
        // map.centerAndZoom(that.center, 12); // 初始化地图，设置中心点坐标和地图级别

        map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放

        function cityResult(result) {
          var cityName = result.name;
          //核心类Map的方法：setCenter(center:Ponit|String)
          //设置地图中心点。center除了可以为坐标点以外，还支持城市名
          // map.setCenter(cityName);
          // alert("当前定位城市:" + cityName);
          that.cityCenter = cityName;
          console.log(cityName, that.cityCenter);
        }
        var myCity = new BMapGL.LocalCity();
        myCity.get(cityResult);
        setTimeout(() => {
          console.log("that.cityCenter", that.cityCenter);
          console.log("that.center", that.center, that.center != "");
          // 初始化地图，设置中心点坐标和地图级别
          map.centerAndZoom(
            that.center != "" ? that.center : that.cityCenter,
            12
          );
        }, 500);

        // console.log(map.centerAndZoom);
        // var scaleCtrl = new BMapGL.ScaleControl(); // 添加比例尺控件
        // map.addControl(scaleCtrl);
        var zoomCtrl = new BMapGL.ZoomControl(); // 添加缩放控件
        map.addControl(zoomCtrl);
        // var cityCtrl = new BMapGL.CityListControl(); // 添加城市列表控件
        // map.addControl(cityCtrl);
        // var pt = new BMapGL.Point(109.792816, 27.702774);
        // var mk = new BMapGL.Marker(pt);
        // map.addOverlay(mk);
        function makeShowInfoString(objUserInfo) {
          that.record = objUserInfo;
          var html = [];
          html.push(` <div>
                        <span>${
                          objUserInfo.ws_status == "1" ? "施工中" : "施工结束"
                        }</span>
                       <span style="color: green"   >  ${
                         objUserInfo.device_status == "1" ? "在线" : ""
                       } </span>
                       <span style="color: red" >
                      ${
                        objUserInfo.device_status == "2"
                          ? objUserInfo.camera == "1"
                            ? "离线"
                            : ""
                          : ""
                      }
                       </span>
                       <span style="color: blue"  >
                        ${objUserInfo.camera == "2" ? "无摄像头" : ""} </span>
                   </div>
                 <a  onclick="JumpSubpage( 'SiteParticulars', '详情')" title="点击查看工地信息" > ${
                   objUserInfo.ws_name
                     ? objUserInfo.ws_name.replace(/直辖市|-北京|-天津/g, "")
                     : ""
                 } </a>
            <div>
              <span>工程管家：</span><span>${
                objUserInfo.w_name_gj ? objUserInfo.w_name_gj : ""
              }  </span>
              <span>${objUserInfo.gj_phone ? objUserInfo.gj_phone : ""}</span>
            </div>
            <div>
              <span>项目经理：</span>${
                objUserInfo.w_name_jl ? objUserInfo.w_name_jl : ""
              }<span>${objUserInfo.jl_phone ? objUserInfo.jl_phone : ""}</span>
            </div>
             <div class="TClist">
                <span class="zhibo"   onclick="JumpSubpage( 'SitePreview', '直播')"  style="display:${
                  objUserInfo.device_status == "1" &&
                  that.isShowBtns.indexOf(
                    "SiteMap-/api/worksite/camera/video"
                  ) > -1
                    ? "block"
                    : "none"
                }">
                 直播 </span>
                <span class="kuaizhao" onclick="JumpSubpage( 'SiteSnapshot', '快照')" style="display:${
                  that.isShowBtns.indexOf("SiteMap-/api/worksite/snapshot") > -1
                    ? "block"
                    : "none"
                }">快照</span>
                <span class="huifang" onclick="JumpSubpage( 'SitePlayback', '回放')"
                style="display:${
                  that.isShowBtns.indexOf(
                    "SiteMap-/api/worksite/playbackstart"
                  ) > -1
                    ? "block"
                    : "none"
                }"
                >回放</span>
                <span class="kaoqin" onclick="JumpSubpage( 'SiteAttend', '考勤')"
                 style="display:${
                   that.isShowBtns.indexOf("SiteMap-/api/worksite/attendance") >
                   -1
                     ? "block"
                     : "none"
                 }"
                >考勤</span>
                <span class="xiangqing" onclick="JumpSubpage( 'SiteParticulars', '详情')" 
                 style="display:${
                   that.isShowBtns.indexOf(
                     "SiteMap-/api/worksite/mapsitedetail"
                   ) > -1
                     ? "block"
                     : "none"
                 }"
                >工地详情</span>
            </div>
           `);

          return html.join("");
        }
        var data = [];
        that.points1.forEach((el) => {
          if (el.lng != null && el.lng.length > 8) {
            // console.log(el.lng, el.lat);
            if (el.device_status == "1") {
              //在线
              data.push({
                geometry: {
                  type: "Point",
                  coordinates: [el.lng, el.lat],
                },
                ws_id: el.ws_id,
                // ws_name: el.ws_name
                //   ? el.ws_name.replace(/直辖市|-北京|-天津/g, "")
                //   : "", //工地名称
                // w_name_gj: el.gj_name, //工地管家
                // w_name_jl: el.jl_name, //工地项目经理
                // bc_name: el.bc_name, //分公司id
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                // no_work: el.no_work, //无人施工
                ws_status: el.ws_status, //工地状态
                // gj_phone: el.gj_phone,
                // jl_phone: el.jl_phone,
                properties: {
                  // 可以为每个点使用不同的icon
                  width: 30,
                  height: 35,
                  // icon: require("../../assets/images/map_success2.png"), //有效icon
                  icon: require("../../assets/images/green.png"), //有效icon
                },
              });
            } else if (el.camera == "2" && el.device_status == "2") {
              data.push({
                geometry: {
                  type: "Point",
                  coordinates: [el.lng, el.lat],
                },
                ws_id: el.ws_id,
                // ws_name: el.ws_name
                //   ? el.ws_name.replace(/直辖市|-北京|-天津/g, "")
                //   : "", //工地名称
                // w_name_gj: el.gj_name, //工地管家
                // w_name_jl: el.jl_name, //工地项目经理
                // bc_name: el.bc_name, //分公司id
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                // no_work: el.no_work, //无人施工
                ws_status: el.ws_status, //工地状态
                // gj_phone: el.gj_phone,
                // jl_phone: el.jl_phone,
                properties: {
                  // 可以为每个点使用不同的icon
                  width: 30,
                  height: 35,
                  icon: require("../../assets/images/blue.png"),
                },
              });
            } else if (el.device_status == "2" && el.camera != "2") {
              //离线
              data.push({
                geometry: {
                  type: "Point",
                  coordinates: [el.lng, el.lat],
                },
                ws_id: el.ws_id,
                // ws_name: el.ws_name
                //   ? el.ws_name.replace(/直辖市|-北京|-天津/g, "")
                //   : "", //工地名称
                // w_name_gj: el.gj_name, //工地管家
                // w_name_jl: el.jl_name, //工地项目经理
                // bc_name: el.bc_name, //分公司id
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                // no_work: el.no_work, //无人施工
                ws_status: el.ws_status, //工地状态
                // gj_phone: el.gj_phone,
                // jl_phone: el.jl_phone,
                properties: {
                  // 可以为每个点使用不同的icon
                  width: 30,
                  height: 35,
                  icon: require("../../assets/images/red.png"), //有效icon
                  // icon: require("../../assets/images/map_error.png"), //有效icon
                },
              });
            }
          }
        });

        var view = new mapvgl.View({
          map: map,
        });
        var infoWindow = new BMapGL.InfoWindow(""); // 创建信息窗口对象
        var layer = new mapvgl.IconLayer({
          // width: 26,
          // height: 33,
          // width: 20,
          // height: 30,
          // offset: [0, -153 / 12],
          // opacity: 0.8,
          // scale: 0.8,
          icon: require("../../assets/images/map_success.png"),
          enablePicked: true, // 是否可以拾取
          selectedIndex: -1, // 选中项
          // selectedColor: "rgba(255, 255, 255, 0.5)", // 选中项颜色
          selectedColor: "green",
          // collides: true, // 是否开启碰撞检测, 数量较多时建议打开
          // flat: true,
          autoSelect: true, // 根据鼠标位置来自动设置选中项
          // padding: [0, 0],
          onClick: (el) => {
            // 点击事件
            // console.log(el.dataItem.ws_id);
            if (el) {
              // 点击时，根据接口，获取信息
              let params = {
                ws_id: el.dataItem.ws_id,
              };
              let mapsitedetailData = {};
              Getmapsitedetail(params)
                .then((res) => {
                  // console.log(res.data);
                  mapsitedetailData = res.data;
                })
                .then(() => {
                  console.log(mapsitedetailData.gj_phone);
                  const objUserInfo = {
                    // name: mapsitedetailData.ws_status,
                    // number: "131424535464",
                    // sipState: "在线",
                    longitude: el.dataItem.geometry.coordinates[0],
                    latitude: el.dataItem.geometry.coordinates[1],
                    ws_id: el.dataItem.ws_id,
                    ws_name: mapsitedetailData.ws_name.replace(
                      /直辖市|-北京|-天津/g,
                      ""
                    ), //工地名称
                    // ws_name: el.dataItem.ws_name, //工地名称
                    w_name_gj: mapsitedetailData.gj_name, //工地管家
                    w_name_jl: mapsitedetailData.jl_name, //工地项目经理
                    bc_name: el.dataItem.bc_name, //分公司id
                    camera: el.dataItem.camera, //摄像头
                    device_status: el.dataItem.device_status, //摄像头状态
                    no_work: mapsitedetailData.no_work, //无人施工
                    ws_status: el.dataItem.ws_status, //工地状态
                    gj_phone: mapsitedetailData.gj_phone,
                    jl_phone: mapsitedetailData.jl_phone,
                    ws_status: mapsitedetailData.ws_status, //施工状态
                    ws_bind_d_idsList: mapsitedetailData.d_list,
                  };
                  const html = makeShowInfoString(objUserInfo);
                  infoWindow.setTitle("");
                  // objUserInfo.name + "(" + objUserInfo.sipState + ")"
                  infoWindow.setContent(html);
                  infoWindow.redraw();
                  infoWindow.disableCloseOnClick();
                  var pt = new BMapGL.Point(
                    el.dataItem.geometry.coordinates[0],
                    el.dataItem.geometry.coordinates[1]
                  );
                  map.openInfoWindow(infoWindow, pt);
                });
            } else {
              return;
            }
          },
          onDblClick: (e) => {
            console.log("double click", e);
          },
          onRightClick: (e) => {
            console.log("right click", e);
          },
        });

        view.addLayer(layer);
        layer.setData(data);

        map.setDefaultCursor("default");
      }

      baiduMapInit();
    },
    // 点击工地地址跳转
    jumpToSiteList() {
      // console.log("jumpToSiteList");
      // this.$router.push({
      //   path: "SiteList",
      //   query: {
      //     ws_name: this.record.ws_name,
      //   },
      // });
    },
    JumpSubpage(showChild, title) {
      console.log(showChild, title);
      // 根据点击按钮不同，显示不同内容
      this.visible = true;
      this.title = "工地" + title;
      // this.record = this.record;
      // console.log(this.record);
      // console.log(this.record.ws_id);
      this[showChild] = true;
    },
    onClose() {
      // 关闭弹出框时的处理
      this.visible = false;
      this.SiteSnapshot = false;
      this.SiteDetail = false;
      this.SiteParticulars = false;
      this.SiteAttend = false;
      this.SiteEdit = false;
      if (this.SitePreview) {
        this.$refs.SitePreview.close();
        this.SitePreview = false;
      }
      if (this.SitePlayback) {
        this.$refs.SitePlayback.close();
        this.SitePlayback = false;
      }
    },

    // 关闭直播   供直播组件调用关闭直播
    onClosePreview() {
      this.SitePreview = false;
    },
    infoWindowClose() {
      this.show = false;
      // console.log(this.$refs.valueLabel);
      this.valueLabel = this.$refs.valueLabel.valueLabel;
    },
    // 放大地图，隐藏其余
    MapZoom() {
      let element = document.getElementById("screen"); //指定全屏区域元素
      // let status_con = document.getElementById("status-con");
      let exit_icon = document.getElementById("exit_icon");
      screenfull.toggle(element); //全屏显示
      // status_con.style.display = "none";
      exit_icon.style.display = "block";
      console.log("全屏");
    },
    checkFull() {
      let isFull = window.fullScreen || document.webkitIsFullScreen;
      this.isFull = isFull;
    },
    // 退出全屏按钮
    MapZoom_Exit() {
      this.isFull = false;
      console.log("点击了MapZoom_Exit", this.isFull);
      let element = document.getElementById("screen"); //指定全屏区域元素
      screenfull.exit(element); //退出全屏
    },
    getPopupContainer() {
      return document.getElementById("exit_icon");
    },
    // @SelectChange="($event) => SelectChange()"
    // SelectChange() {
    //   this.selectValue = this.$refs.valueLabel.selectValue;
    //   console.log("1111");
    // },
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 90%;
  #screen {
    width: 100%;
    height: 100%;
    display: flex;
    .exit_control {
      width: 15px;
      height: 15px;
      margin-left: -50px;
      margin-top: 20px;
      z-index: 9999 !important;
      img {
        width: 15px;
        height: 15px;
      }
      #exit_icon {
        display: none;
      }
    }
  }
  .status-con {
    position: absolute;
    width: 100px;
    height: 100px;
    background: #fff;
    border: rgba(0, 0, 0, 0.342) solid 1px;
    // display: flex;
    z-index: 999 !important;
    margin-bottom: -50px;
    text-align: left;
    // margin-top: 100px;
  }
  #canvas {
    width: 0%;
    height: 0%;
  }
  #container {
    width: 100%;
    height: 100%;
  }
  /deep/ .BMap_bubble_pop,
  /deep/ .BMap_bubble_center {
    width: 300px !important;
  }
  /deep/.BMap_bubble_content {
    width: 280px !important;
  }
  /deep/ .BMap_bubble_content {
    margin-top: -15px;
  }
  .explain_map_flex {
    display: flex;
    justify-content: space-between;
  }
  .explain_map {
    height: 25px;
    line-height: 15px;
    text-align: left;
    p {
      margin: 0;
      padding: 0;
    }
  }
  .explain_mapBtn {
    margin-top: -3px;
    // height: 25px;
    // line-height: 15px;
    width: 50px;
    img {
      width: 10px;
      height: 10px;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
}

/deep/.TClist {
  // height: 40px;
  // line-height: 40px;
  display: flex;
  span {
    color: #fff;
    margin-right: 2px;
    padding: 5px;
    a {
      color: white;
    }
  }
}
/deep/.BMap_cpyCtrl,
.anchorBL,
/deep/.anchorBL {
  display: none;
  // background-color: ;
}
/deep/.zhibo {
  background: url("../../assets/images/site_img/zhibo.png");
  color: #fff;
}
/deep/.huifang {
  background: url(../../assets/images/site_img/huifang.png);
  // padding: 2px;
  color: #fff;
}
/deep/.kaoqin {
  background: url(../../assets/images/site_img/kaoqin.png);
  // padding: 2px;
  color: #fff;
}
/deep/.kuaizhao {
  background: url(../../assets/images/site_img/kuaizhao.png);
  // padding: 2px;
  color: #fff;
}
/deep/.xiangqing {
  background: url(../../assets/images/site_img/zhibo.png);
  // padding: 2px;
  color: #fff;
}
.Inputaddress {
  z-index: 999999 !important;
  position: absolute;
  // width: 100%;
  height: 50px;
  text-align: center;
  line-height: 30px;
  margin-left: 120px;
  margin-top: 10px;
  // background-color: #fff;
  // margin: 10px 0;

  #searchResultPanel {
    // position: absolute;
  }
  #suggestId {
    box-shadow: 3px 3px #ccc;
    border: #ccc 1px solid;
  }
}
.map_status {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: relative;
}
</style>
